import React, { Fragment, useRef, useState, useEffect } from 'react';
import Loader from '../Loader';
import { Bar } from 'react-chartjs-2';
import { colori_blu, printData } from '../../common/utils.js';
import { GiMoneyStack } from 'react-icons/gi';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  interaction: {
    intersect: true,
    mode: 'x'
  },
  scales: {
    y: {
      stacked: true,
      ticks: {
        color: 'rgb(0, 109, 151)',
        font: {
          size: 14
        }
      }
    },
    y1: {
      stacked: true,
      type: 'linear',
      display: true,
      position: 'right',
      grid: {
        drawOnChartArea: false
      },
      ticks: {
        color: 'rgb(255, 102, 0)',
        font: {
          size: 14
        }
      }
    },
    x: {
      stacked: true,
      ticks: {
        color: 'black',
        font: {
          size: 14
        }
      }
    }
  },
  plugins: {
    legend: {
      display: 'top'
    },
    title: {
      display: true,
      text: 'Incassi (€)',
      color: 'black',
      padding: {
        top: 10,
        bottom: 30
      },
      font: {
        size: 28,
        weight: '500',
        family: 'Barlow'
      }
    }
  }
};

const plugins = [
  {
    beforeDraw: function (chart) {
      const { ctx } = chart;
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    }
  }
];

export default function AndamentoFilm({ data, versioni }) {
  const [filterData, setFilterData] = useState(Array.isArray(data) ? data : []);
  const chartRef = useRef();

  const getCumulato = (array, campo) => {
    const array_filtrato = array.filter((v) => versioni.indexOf(v.versione) !== -1);
    const ascisse = Array.from(new Set(array_filtrato.map((x) => x.data)));
    let totale = 0;
    const cumulato = ascisse.map((d) => {
      totale =
        totale +
        array_filtrato
          .filter((x) => x.data === d)
          .reduce((partialSum, a) => partialSum + a[campo], 0);
      return totale;
    });
    return cumulato;
  };

  const getTotalePerVersione = (array, versione, campo) => {
    const array_filtrato = array.filter((v) => v.versione === versione);
    const ascisse = Array.from(new Set(array_filtrato.map((x) => x.data)));
    const cumulato = ascisse.map((d) => {
      return array_filtrato
        .filter((x) => x.data === d)
        .reduce((partialSum, a) => partialSum + a[campo], 0);
    });
    return cumulato;
  };

  useEffect(() => {
    if (data) {
      setFilterData(Array.isArray(data) ? data : []);
    }
  }, [data]);

  if (!data) return <Loader messaggio="Aggiornamento del grafico in corso..." />;

  if (!Array.isArray(data) || data.length === 0) {
    return (
      <div className="mx-auto text-center mb-5" style={{ maxWidth: '800px' }}>
        <h2 style={{ textAlign: 'center' }}>
          <GiMoneyStack /> Incassi (€)
        </h2>
        <p>Non ci sono dati da visualizzare</p>
      </div>
    );
  }

  const dati_versioni = Array.isArray(filterData)
    ? filterData.filter((v) => versioni.some((vs) => vs === v.versione))
    : [];
  const dati_grafico =
    dati_versioni.length > 0
      ? {
          labels: Array.from(new Set(dati_versioni.map((x) => printData(new Date(x['data']))))),
          datasets: [
            {
              type: 'line',
              label: 'Cumulato',
              borderRadius: 4,
              pointRadius: 0,
              borderColor: 'rgb(255, 102, 0)',
              data: getCumulato(dati_versioni, 'incassi'),
              yAxisID: 'y1'
            },
            ...versioni.map((v) => {
              return {
                type: 'bar',
                label: versioni.length > 1 ? v : 'Incasso',
                barPercentage: 0.9,
                categoryPercentage: 1.0,
                borderRadius: 4,
                data: getTotalePerVersione(dati_versioni, v, 'incassi'),
                backgroundColor: colori_blu[v],
                yAxisID: 'y',
                maxBarThickness: 100
              };
            })
          ]
        }
      : null;

  return (
    <Fragment>
     {/*  {error && <div className="alert alert-danger">{error}</div>}
 */}
      {dati_grafico && (
        <div className="mb-5">
          <div className="mb-5" style={{ width: '900px', height: '450px', margin: 'auto' }}>
            <Bar ref={chartRef} data={dati_grafico} options={options} plugins={plugins} />
          </div>
        </div>
      )}
    </Fragment>
  );
}
