import React, { useState } from 'react';
import { Offcanvas, Button, ListGroup, Row, Col, Modal, Form } from 'react-bootstrap';
import { BsList, BsStarFill, BsPencil, BsBoxArrowInUpRight, BsXCircle, BsCloudCheck } from 'react-icons/bs';
import moment from 'moment';

function DrawerComponent({
  isPreferred,
  data,
  preferredSave,
  selectResearchFromDrawer,
  deleteResearch,
  isPivot = false
}) {
  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [newName, setNewName] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDeleteModalClose = () => setShowDeleteModal(false);
  const handleRenameModalClose = () => setShowRenameModal(false);

  const handleDeleteClick = (item) => {
    setSelectedItem(item);
    setShowDeleteModal(true);
  };

  const handleRenameClick = (item) => {
    setSelectedItem(item);
    setNewName(item.description);
    setShowRenameModal(true);
  };

  const handleSelectClick = (item) => {
    selectResearchFromDrawer(item);
    setTimeout(() => {
      setShow(false);
    }, 500);
  };

  const handleDeleteConfirm = () => {
    deleteResearch(selectedItem);
    handleDeleteModalClose();
  };

  const handleRenameConfirm = (description) => {
    handleRenameModalClose();
    preferredSave({ ...selectedItem, description: description });
    if (!isPreferred) {
      setShow(false);
    }
  };

  return (
    <>
      <button
        className={isPreferred ? 'btn btn-danger mx-1' : 'btn btn-blue-cinetel mx-1'}
        title="closeSearch"
        onClick={handleShow}>
        {isPreferred ? (
          <BsStarFill className="position-relative me-1" style={{ marginBottom: '3px' }} />
        ) : isPivot ? (
          <BsCloudCheck className="position-relative me-1" style={{ marginBottom: '3px' }} />
        ) : (
          <BsList className="position-relative me-1" style={{ marginBottom: '3px' }} />
        )}

        {isPreferred ? 'Preferiti' : isPivot ? 'Salvati' : 'Recenti'}
      </button>

      <Offcanvas show={show} onHide={handleClose} placement="start" style={{ width: '60%' }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title> {isPreferred ? 'Preferiti' : 'Recenti'}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ListGroup>
            {data?.map((item, index) => (
              <ListGroup.Item key={index}>
                <Row className="align-items-center">
                  <Col>
                    {isPreferred ? (
                      <>
                        {item.description}
                        <br></br>
                      </>
                    ) : (
                      ''
                    )}
                    
                    {moment(item.data).format('DD/MM/YYYY HH:mm:ss')}
                  </Col>
                  <Col className="text-end align-items-center">
                    <Button
                      variant="danger"
                      className="ms-2"
                      size="sm"
                      onClick={() => handleDeleteClick(item)}>
                      <BsXCircle
                        className="position-relative me-1"
                        style={{ marginBottom: '3px' }}
                      />
                      Elimina
                    </Button>

                    {isPreferred ? (
                      <Button
                        variant="warning"
                        className="ms-2"
                        size="sm"
                        onClick={() => handleRenameClick(item)}>
                        <BsPencil
                          className="position-relative me-1"
                          style={{ marginBottom: '3px' }}
                        />
                        Rinomina
                      </Button>
                    ) : (
                      <Button
                        variant="success"
                        className="ms-2"
                        size="sm"
                        onClick={() => handleRenameClick(item)}>
                        <BsStarFill
                          className="position-relative me-1"
                          style={{ marginBottom: '3px' }}
                        />
                        Salva nei Preferiti
                      </Button>
                    )}
                    <Button
                      variant="secondary"
                      size="sm"
                      className="ms-2"
                      onClick={() => handleSelectClick(item)}>
                      <BsBoxArrowInUpRight
                        className="position-relative me-1"
                        style={{ marginBottom: '3px' }}
                      />
                      Utilizza
                    </Button>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={showDeleteModal} onHide={handleDeleteModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Conferma Cancellazione</Modal.Title>
        </Modal.Header>
        <Modal.Body>Sei sicuro di voler cancellare questo elemento?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteModalClose}>
            Annulla
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Cancella
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showRenameModal} onHide={handleRenameModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{isPreferred ? 'Rinomina Elemento' : 'Salva nei Preferiti'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formNewName">
              <Form.Label>{isPreferred ? 'Modifica Descrizione' : 'Nuova Descrizione'}</Form.Label>
              <Form.Control
                type="text"
                defaultValue={''}
                value={newName || ''}
                onChange={(e) => setNewName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleRenameModalClose}>
            Annulla
          </Button>
          <Button variant="primary" onClick={() => handleRenameConfirm(newName)}>
            Salva
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DrawerComponent;
