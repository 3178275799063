import React, { Fragment, useCallback, useState, useEffect } from 'react';
import fetcher from '../../api/fetcher';
import FiltroVersioni from '../filtri/FiltroVersioni';
import GraficoAndamenti from './GraficoAndamenti';
import Loader from '../Loader';
import TabellaAndamenti from './TabellaAndamenti';

export default function AnalisiAndamentiTest({ film, tipoperiodo, maxperiodi, mindata, maxdata, preview }) {
    const [serie, setSerie] = useState([]);
    const [versioniTrovate, setVersioniTrovate] = useState();
    const [versioniSelezionate, setVersioniSelezionate] = useState();

    useEffect(() => {
        let doUpdate = true;
        setSerie([]);
        film.forEach(f => {
            
            
            
            let url = `/film/${f.valore}/andamenti/${tipoperiodo.valore}`;
            
            
            let url_params = {};
            if (maxperiodi > 0)
                url_params['maxperiodi'] = maxperiodi;
            if (mindata !== '')
                url_params['mindata'] = mindata;
            if (maxdata !== '')
                url_params['maxdata'] = maxdata;
            if (preview !== false)
                url_params['preview'] = "true";
            if (Object.keys(url_params).length > 0) {
                const params_query = new URLSearchParams(url_params);
                url = url + '?' + params_query.toString();
            }
            

            fetcher(url)
                .then(({data}) => {
                    if (doUpdate) {                        
                        data.progress_film = f.valore;
                        data.titolo = f.descrizione;
                        setSerie(dati => [...dati, data]);
                    }
                })
                .catch(error => {
                    if (doUpdate)
                        console.warn('erroreeeeeeee film');
                });
        })
        return () => doUpdate = false;
    }, [film, tipoperiodo, maxperiodi, mindata, maxdata]);

    useEffect(() => {
        let versioni = [];
        if (serie) {
            
            
            for (let f = 0; f < serie.length; f++) {
                for (let i = 0; i < serie[f].data.length; i++) {
                    
                    for (let c = 0; c < serie[f].data[i].cinema.length; c++) {
                        const all_cinema = serie[f].data[i].cinema;
                        versioni.push(...all_cinema.map(x => x.versione))
                    }
                }
            }
            versioni = Array.from(new Set(versioni));
        }
        setVersioniTrovate(versioni)
    }, [serie])

    const assegnaVersioni = useCallback(valore => {
        
        
        setVersioniSelezionate(valore)
    }, [])

    
    
    
    
    const numero_film = serie ? serie.length : 0;

    if (serie.length === film.length && film.length > 0) {
        if (numero_film === 0) {
            return (
                <div className="container">
                    <div className='row mb-5 p-4'>
                        <h2 className="text-center mb-5">Nessun dato disponibile per la selezione</h2>
                    </div>
                </div>
            )
        }

        const max_num_dati = Math.max(...serie.map(x=>x.data.length))
        if (max_num_dati === 0) {
            return (
                <div className="container">
                    <div className='row mb-5 p-4'>
                        <h2 className="text-center mb-5">Nessun dato trovato</h2>
                    </div>
                </div>
            )
        }

        return (
            <Fragment>
                <div className="mx-auto mb-4 mt-5" style={{ maxWidth: '300px' }}>
                    <FiltroVersioni
                        versioni={versioniTrovate}
                        setVersioniSelezionate={assegnaVersioni}
                    />

                </div>

                {versioniSelezionate && versioniSelezionate.length > 0 && (
                    <Fragment>
                        <GraficoAndamenti
                            versioni={versioniSelezionate.map(x => x.valore)}
                            serie={serie}
                        />

                        <TabellaAndamenti
                            versioni={versioniSelezionate.map(x => x.valore)}
                            serie={serie}
                        />
                    </Fragment>
                )}
            </Fragment>
        )
    }
    else {
        if (film.length > 0)
            return <Loader messaggio={"Caricamento dei risultati"} />;           
        return null;
    }

}