import React, { Fragment, useEffect, useState } from 'react';
import FiltroItem from './FiltroItem';
import { BsFillExclamationCircleFill, BsFillTrashFill, BsBookmarkCheckFill } from 'react-icons/bs';
import Loader from '../Loader';
import ErrorMessage from '../ErrorMessage';
import { Col, Row } from 'react-bootstrap';
import MultiselectMoreFileds from '../film/MultiselectMoreFileds';

const MAX_ITEMS_VISUALIZZATI = 500;

export default function Filtro({
  titolo,
  dati,
  onChange,
  selezioneRichiesta = false,
  hasRecenti = false,
  selectAll,
  maxHeight,
  errore = null,
  showNumeroVoci = true,
  showRicerca = true,
  selezioneSingola = false,
  itemsSelected = [],
  isResearchSelected = false,
  isCanvas=false
}) {
  const [filtro, setFiltro] = useState('');
  const [checked, setChecked] = useState([]);
  const [showRecenti, setShowRecenti] = useState(hasRecenti);

  useEffect(() => {
    if (selectAll) {
      if (dati && !errore) setChecked([...dati]);
    } else {
      if (selezioneSingola) {
        if (dati && !errore) setChecked([dati[0]]);
      } else {
        setChecked([...itemsSelected]);
      }
    }
  }, [dati]);

  useEffect(() => {
    if (itemsSelected.length > 0) {
      setChecked([...itemsSelected]);
    }
    if(selezioneSingola){
      setChecked([itemsSelected]);
    }
  }, [isResearchSelected]);

  const onChangeHandler = (item) => {
    if (selezioneSingola) setChecked([item]);
    else {
      if (!checked.some((c) => c.valore === item.valore)) {
        setChecked((lista) => [...lista, item]);
      } else {
        if (!selezioneRichiesta || checked.length > 1)
          setChecked((lista) => lista.filter((x) => x.valore !== item.valore));
      }
    }
  };

  useEffect(() => {
    if (selezioneSingola) {
      onChange(checked.length > 0 ? checked[0] : null);
    } else {
      onChange(checked);
    }
  }, [checked]);

  let Body;
  if (errore) {
    Body = (
      <div className="row mb-3 align-items-center">
        <div className="col">
          <ErrorMessage messaggio={errore.message} />
        </div>
      </div>
    );
  } else {
    if (!dati) {
      Body = (
        <div className="row mb-3 align-items-center">
          <div className="col">
            <Loader messaggio={'Caricamento ' + titolo.toUpperCase()} />
          </div>
        </div>
      );
    } else {
      if (dati.length <= 1) {
        return <></>;
      }

      const dati_filtrati = dati.filter(
        (x) =>
          x.descrizione.toLowerCase().includes(filtro.trim().toLowerCase()) &&
          (!hasRecenti || (showRecenti ? x?.recente : true))
      );
      const numItemsFiltrati = dati_filtrati && dati_filtrati.length;

      const Items =
        dati_filtrati &&
        dati_filtrati.slice(0, MAX_ITEMS_VISUALIZZATI).map((x) => {
          return (
            <FiltroItem
              key={x?.valore}
              checked={checked.filter((c) => c.valore === x.valore).length > 0}
              onChange={onChangeHandler}
              selezioneSingola={selezioneSingola}
              item={x}
            />
          );
        });

      Body = (
        <Fragment>
          {showRicerca && (
            <div className="row mb-2 align-items-center">
              <div className="col">
                <Row className="align-items-center">
                  <Col xs={12} md={8}>
                    <input
                      className="form-control w-100"
                      placeholder="Ricerca per testo"
                      type="search"
                      value={filtro}
                      onChange={(e) => setFiltro(e.target.value)}
                      style={{ textTransform: 'uppercase' }}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <MultiselectMoreFileds
                      value={[]}
                      options={dati}
                      onChange={setChecked}
                      optionLabel={'descrizione'}
                      optionValue={'valore'}
                      checked={checked}
                      isCanvas={isCanvas}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          )}
          {hasRecenti && (
            <div
              className="item-filtro mb-2 text-center"
              onClick={() => setShowRecenti((val) => !val)}
              style={
                showRecenti
                  ? {
                      fontWeight: '500',
                      color: 'rgb(0, 109, 151)',
                      backgroundColor: 'aliceblue',
                      lineHeight: '1',
                      padding: '3px 0'
                    }
                  : {
                      fontWeight: 'normal',
                      color: 'black',
                      backgroundColor: 'white',
                      lineHeight: '1',
                      padding: '3px 0'
                    }
              }>
              <span className="ms-1">
                {showRecenti
                  ? 'Visualizza anche le voci meno recenti'
                  : 'Mostra solo le voci recenti'}
              </span>
            </div>
          )}
          {!selezioneSingola && showNumeroVoci && (
            <div className="row mb-2 align-items-center">
              <div className="col" style={{ color: 'gray' }}>
                {dati_filtrati && dati_filtrati.length > MAX_ITEMS_VISUALIZZATI ? (
                  <Fragment>
                    <BsFillExclamationCircleFill
                      className="me-1 position-relative"
                      style={{ top: '-1px', fontSize: '1.2rem' }}
                    />
                    <i>
                      Visualizzate solo le prime {MAX_ITEMS_VISUALIZZATI} voci su {numItemsFiltrati}
                    </i>
                  </Fragment>
                ) : (
                  <Fragment>
                    <i>Visualizzate {numItemsFiltrati} voci</i>
                  </Fragment>
                )}
              </div>
            </div>
          )}
          <div
            className="position-relative"
            style={{ overflowY: 'auto', maxHeight: maxHeight?.length > 0 ? maxHeight : 'none' }}>
            {Items.length > 0 ? (
              Items
            ) : (
              <div style={{ color: 'gray', fontStyle: 'italic' }}>Nessuna voce da mostrare</div>
            )}
          </div>
        </Fragment>
      );
    }
  }

  return (
    <div className="pannello mb-3">
      <div
        className="text-center py-1 px-2 bg-gradient"
        style={{ backgroundColor: 'var(--blue-cinetel)', color: 'white' }}
        title={
          checked && checked.length > 0
            ? checked.length < 50
              ? 'Hai selezionato:\n' + checked.map((x) => '  > ' + x.descrizione).join('\n')
              : 'Troppe voci selezionate da visualizzare!'
            : 'Nessuna selezione'
        }>
        {dati && checked.length < dati.length && !selezioneSingola && (
          <span className="float-start">
            <BsBookmarkCheckFill
              className="position-relative me-1"
              style={{ top: '-1px', cursor: 'pointer' }}
              title="Seleziona tutti"
              onClick={() => setChecked([...dati])}
            />
          </span>
        )}
        {checked.length > 0 && dati && !selezioneSingola && !selezioneRichiesta && (
          <span className="float-start">
            <BsFillTrashFill
              className="position-relative"
              style={{ top: '-1px', cursor: 'pointer' }}
              title="Deseleziona tutti"
              onClick={() => setChecked([])}
            />
          </span>
        )}
        {titolo.toUpperCase()}
        {!selezioneSingola && dati && (
          <span className="float-end">
            {checked.length}/{dati.length}
          </span>
        )}
      </div>
      <div className="p-2 p-md-3">{Body}</div>
    </div>
  );
}
