import React, { useEffect, useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import useFetch, { StatiRichiesta } from '../../hooks/useFetch';
import useScrollTop from '../../hooks/useScrollTop';
import Loader from '../Loader';
import AndamentoFilm from './AndamentoFilm';
import DatiFilm from './DatiFilms';
import GraficoRegioni from './GraficoRegioni';
import TabellaRegioni from './TabellaRegioni';
import FiltroVersioni from '../filtri/FiltroVersioni';
import UsciteContemporanee2 from './UsciteContemporanee2';
import Mappa from '../Mappa';
import { printInteroConSeparatoriMigliaia } from '../../common/utils';
import { BsPeopleFill, BsSearch } from 'react-icons/bs';
import { GiTwoCoins } from 'react-icons/gi';
import fetcher from '../../api/fetcher';
import { useCallback } from 'react';
import moment from 'moment';

const oggi = new Date();
const ieri = new Date(oggi.setDate(oggi.getDate() - 1));

export default function FilmPage() {
  let params = useParams();
  const [versioniSelezionate, setVersioniSelezionate] = useState(null);
  const [versioniTrovate, setVersioniTrovate] = useState();
  const { data, status, setData } = useFetch(`/films/${params.progress_film}`);
  const { data: data_cinema, setData: setDataCinema} = useFetch(`/films/${params.progress_film}/totali`);

  const film = data && data.data;
  
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [isLoading, setIsLoading] = useState(false);


  const sanitizeAl = useCallback(() => {
    if (new Date(endDate) > ieri) {
      setEndDate(ieri.toISOString().split('T')[0]);
    }

    if (new Date(endDate) < new Date(startDate)) {
      setEndDate(new Date(startDate).toISOString().split('T')[0]);
    }
  }, [endDate, startDate]);


  const FilterDateFilm = async () => {
    setIsLoading(true);
    try {
      const { data: data_cinema_date } = await fetcher(
        `/films/${params.progress_film}/totali${
          startDate ? '?date_from=' + moment(startDate).format("DD/MM/YYYY") : ''
        }${endDate ? '&date_to=' + moment(endDate).format("DD/MM/YYYY") : ''}`
      );
      setDataCinema(data_cinema_date)
      const { data } = await fetcher(
        `/films/${params.progress_film}${
          startDate ? '?date_from=' + moment(startDate).format("DD/MM/YYYY") : ''
        }${endDate ? '&date_to=' + moment(endDate).format("DD/MM/YYYY") : ''}`
      );
      setData(data)
    } catch (error) {
      console.error('Error fetching data:', error);
      /* setError('Errore nel caricamento dei dati'); */
    } finally {
      setIsLoading(false);
    }
  };

  useScrollTop();

  useEffect(() => {
    if (data && data.data.andamento.length > 0) {
      const versioni_trovate = Array.from(new Set(data.data.andamento.map((x) => x.versione)));
      setVersioniTrovate(versioni_trovate);

    }
  }, [data]);


  if (status === StatiRichiesta.Loading || status === StatiRichiesta.Idle || !film)
    return <Loader messaggio={'Caricamento informazioni film...'} />;

  const versioni = versioniSelezionate ? versioniSelezionate.map((x) => x.valore) : [];
  
  return (
    <Fragment>
      <div className="container mt-3">
        <div className="row mb-4">
          <div className="col text-center mt-2">
            <h1>{film.titolo}</h1>
            <h3 style={{ fontSize: '1.5rem', fontWeight: 'normal' }}>
              <GiTwoCoins className="me-1" />{' '}
              {printInteroConSeparatoriMigliaia(
                film.andamento.reduce((partialSum, a) => partialSum + a.incassi, 0)
              )}{' '}
              € | <BsPeopleFill className="me-1 position-relative" style={{ top: '-2px' }} />{' '}
              {printInteroConSeparatoriMigliaia(
                film.andamento.reduce((partialSum, a) => partialSum + a.presenze, 0)
              )}
            </h3>
            <p>
              Regia: {film.regia} | {film.anno_produzione}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-xl-3">
            <DatiFilm film={film} />
          </div>
          <div className="pannello col p-4">
            <div className="mx-auto mb-4" style={{ maxWidth: '300px' }}>
              <FiltroVersioni
                versioni={versioniTrovate}
                setVersioniSelezionate={setVersioniSelezionate}
              />
              <div className="my-5 d-flex flex-column align-items-center" style={{ gap: '10px' }}>
                <div className="p-1 w-100">
                  <label htmlFor="startDate" className="form-label">
                    Dal
                  </label>
                  <input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      /* onBlur={sanitizeDal} */
                      className="form-control w-100"
                    />
                </div>
                <div className="p-1 w-100">
                  <label htmlFor="endDate" className="form-label">
                    Al
                  </label>
                  <input
                    type="date"
                    id="endDate"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    onBlur={sanitizeAl}
                    className="form-control w-100"
                  />
                </div>
                <div className="p-1 w-100 d-flex justify-content-center">
                  <button
                    className="btn btn-outline-blue-cinetel d-flex align-items-center"
                    title="Ricerca"
                    onClick={() => FilterDateFilm()}
                    disabled={!startDate || isLoading}>
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"></span>
                        Caricamento...
                      </>
                    ) : (
                      <>
                        <BsSearch className="me-2" style={{ top: '-1px' }} />
                        Ricerca
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>

            <AndamentoFilm data={film?.andamento} versioni={versioni} />
            {data_cinema && (
              <Fragment>
                {localStorage.getItem('fake') === true && (
                  <div className="py-5">
                    <h4 className="mb-5 text-center">Mappa incassi (€)</h4>
                    <Mappa />
                  </div>
                )}
                <GraficoRegioni dati={data_cinema?.data} versioni={versioni} />
                <TabellaRegioni
                  dati={data_cinema.data}
                  progress_film={params.progress_film}
                  versioni={versioni}
                />
              </Fragment>
            )}
            {!data_cinema && <Loader messaggio={'Caricamento dati regioni e cinema'} />}
            <UsciteContemporanee2
              dati={film.altri_film_in_data}
              dataCentrale={film.prima_programmazione}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
