import React, { Fragment, useState, useRef, useCallback } from 'react';
import useScrollTop from '../../hooks/useScrollTop';
import FiltroFilm from '../filtri/FiltroFilm';
import FiltroTipiPeriodo from '../filtri/FiltroTipiPeriodo';
import { BsBootstrapReboot, BsFilter, BsPlusCircle } from 'react-icons/bs';
import AnalisiAndamenti from './AnalisiAndamenti';
import HeaderTitoli from '../HeaderTitoli';
import DrawerComponent from '../DrawerComponent';
import useFetch from '../../hooks/useFetch';
import fetcher from '../../api/fetcher';
import { Accordion, AccordionButton } from 'react-bootstrap';

const MAX_FILMS_IN_DETTAGLIO = 6;

export default function DettaglioFilmPage() {
  const [tipoperiodoSelezionato, setTipoperiodoSelezionato] = useState(0);
  const [filmSelezionati, setFilmSelezionati] = useState([]);
  const [selectPrefferd, setSelectPrefferd] = useState(false);
  const [nameSelectedResearch, setNameSelectedResearch] = useState('');
  const [maxPeriodiSelezionato, setMaxPeriodiSelezionato] = useState(0);
  const [minDataSelezionata, setMinDataSelezionata] = useState('');
  const [maxDataSelezionata, setMaxDataSelezionata] = useState('');
  const [isContentVisible, setIsContentVisible] = useState(false);

  const [showMaxPeriodi, setShowMaxPeriodi] = useState(false);
  const [showMinData, setShowMinData] = useState(false);
  const [showMaxData, setShowMaxData] = useState(false);
  const [withOutPreview, setWithOutPreview] = useState(false);

  const [tipoperiodo, setTipoperiodo] = useState(-1);
  const [film, setFilm] = useState([]);
  const [maxPeriodi, setMaxPeriodi] = useState(0);
  const [minData, setMinData] = useState('');
  const [maxData, setMaxData] = useState('');

  const [showMore, setShowMore] = useState(false);
  const domRisultati = useRef();
  useScrollTop();

  const numFilmSelezionati = filmSelezionati.length;
  const invalidAnalisi = numFilmSelezionati === 0 || numFilmSelezionati > MAX_FILMS_IN_DETTAGLIO;

  const { data: data_recenti, setData: setRecents } = useFetch(
    '/users/settings/research?type=confronto_film&preferred=false'
  );
  const { data: data_preferiti, setData: setPreferred } = useFetch(
    '/users/settings/research?type=confronto_film&preferred=true'
  );

  const refreshDataResearch = useCallback(async () => {
    fetcher('/users/settings/research?type=confronto_film&preferred=true')
      .then(({ data }) => {
        setPreferred(data);
      })
      .finally(() => {
        /* setLoading(false); */
      });
    fetcher('/users/settings/research?type=confronto_film&preferred=false')
      .then(({ data }) => {
        setRecents(data);
      })
      .finally(() => {
        /* setLoading(false); */
      });
  }, [setPreferred, setRecents]);

  const saveToPreferredResearch = (item) => {
    let payload = { ...item, preferred: true };
    /* setIsLoading(true); */
    fetcher({
      url: `users/settings/research/${item.id}`,
      method: 'PUT',
      data: payload,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(({ data }) => {
        refreshDataResearch();
      })
      .finally(() => {
        /* setLoading(false); */
      });
  };

  const saveResearch = useCallback(
    async (blob) => {
      let payload = {
        blob: JSON.stringify(blob),
        preferred: false,
        description: new Date(),
        isenabled: true,
        type: 'confronto_film'
      };

      /* setIsLoading(true); */
      fetcher({
        url: `users/settings/research`,
        method: 'POST',
        data: payload,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(({ data }) => {
          refreshDataResearch();
        })
        .finally(() => {
          /* setLoading(false); */
        });
    },
    [refreshDataResearch]
  );

  const startAnalisi = useCallback(() => {
    setTipoperiodo(tipoperiodoSelezionato);
    setFilm(filmSelezionati);
    setMaxPeriodi(showMaxPeriodi ? maxPeriodiSelezionato : -1);
    setMinData(showMinData ? minDataSelezionata : '');
    setMaxData(showMaxData ? maxDataSelezionata : '');
    let dataBlob = {
      tipoperiodoSelezionato: tipoperiodoSelezionato,
      filmSelezionati: filmSelezionati,
      maxPeriodiSelezionato: showMaxPeriodi ? maxPeriodiSelezionato : -1,
      maxDataSelezionata: showMaxData ? maxDataSelezionata : '',
      minDataSelezionata: showMinData ? minDataSelezionata : '',
      withOutPreview: withOutPreview
    };
    saveResearch(dataBlob);
    setIsContentVisible(true);
    domRisultati.current.scrollIntoView();
  }, [
    tipoperiodoSelezionato,
    filmSelezionati,
    maxPeriodiSelezionato,
    showMaxPeriodi,
    showMinData,
    minDataSelezionata,
    showMaxData,
    maxDataSelezionata,
    withOutPreview,
    saveResearch
  ]);

  const resetFiltriAvanzati = () => {
    setShowMaxPeriodi(false);
    setMaxPeriodiSelezionato(1);
    setShowMore(false);
    setShowMinData(false);
    setShowMaxData(false);
    setMinDataSelezionata('');
    setMaxDataSelezionata('');
    setWithOutPreview(false);
  };

  const selectResearchFromDrawer = (item) => {
    setShowMore(true);
    setIsContentVisible(false);
    let data = JSON.parse(item.blob);
    setNameSelectedResearch(item.description);
    setSelectPrefferd(!selectPrefferd);
    setFilmSelezionati(data.filmSelezionati);
    setTipoperiodoSelezionato(data.tipoperiodoSelezionato);

    setShowMaxPeriodi(data.maxPeriodiSelezionato > 1);
    setMaxPeriodiSelezionato(data.maxPeriodiSelezionato);

    setShowMinData(data.minDataSelezionata ? true : false);
    setMinDataSelezionata(data.minDataSelezionata);

    setShowMaxData(data.maxDataSelezionata ? true : false);
    setMaxDataSelezionata(data.maxDataSelezionata);
    setWithOutPreview(data.withOutPreview);
  };

  const deleteResearch = useCallback(
    async (item) => {
      /* setIsLoading(true); */
      fetcher({
        url: `users/settings/research/${item.id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(({ data }) => {
          refreshDataResearch();
        })
        .finally(() => {
          /* setLoading(false); */
        });
    },
    [refreshDataResearch]
  );
  return (
    <div style={{ marginBottom: '8rem' }}>
      <div className="container pannello my-5 mx-auto" style={{ maxWidth: '1000px' }}>
        <div className="row mb-5 p-4">
          <div className="row">
            <div className="col-12 d-flex justify-content-between align-items-center">
              {nameSelectedResearch !== '' ? (
                <div className="mx-2 my-2">
                  <button
                    className={'btn btn-success mx-1'}
                    title="closeSearch"
                    onClick={() => window.location.reload()}>
                    <BsPlusCircle
                      className="position-relative me-1"
                      style={{ marginBottom: '3px' }}
                    />
                    Nuova Ricerca
                  </button>
                </div>
              ) : (
                <></>
              )}
              <div className="d-flex ms-auto">
                <div className="mx-2 my-2">
                  <DrawerComponent
                    isPreferred={true}
                    data={data_preferiti?.research}
                    preferredSave={(e) => saveToPreferredResearch(e)}
                    selectResearchFromDrawer={selectResearchFromDrawer}
                    deleteResearch={(e) =>deleteResearch(e)}
                  />
                </div>
                <div className="mx-2 my-2">
                  <DrawerComponent
                    isPreferred={false}
                    data={data_recenti?.research}
                    preferredSave={(e) => saveToPreferredResearch(e)}
                    selectResearchFromDrawer={selectResearchFromDrawer}
                    deleteResearch={(e) =>deleteResearch(e)}
                  />
                </div>
              </div>
            </div>
          </div>

          <HeaderTitoli />
          <h2 className="text-center mb-5">Confronto tra film</h2>
          <div className="col-12">
            <ol>
              <li>Seleziona uno o più film</li>
              <li>Seleziona il periodo su cui effettuare l'analisi</li>
              {/* <li>Seleziona uno o più campi da mostrare nell'analisi</li> */}
              <li>Premi il pulsante AVVIA ANALISI per recuperare i risultati</li>
            </ol>
          </div>
          {nameSelectedResearch !== '' ? (
            <div className="row mb-2">
              <div className="col-12 d-flex justify-content-center align-items-center">
                <h4 className="mx-2">Ricerca Selezionata: {nameSelectedResearch}</h4>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="col-lg">
            <FiltroFilm
              onChange={setFilmSelezionati}
              filmSelezionati={filmSelezionati}
              selectPrefferd={selectPrefferd}
              maxHeight={'270px'}
              selectAll={false}
            />
          </div>
          <div className="col-lg-4">
            <FiltroTipiPeriodo
              onChange={setTipoperiodoSelezionato}
              showRicerca={false}
              selectAll={false}
              tipoperiodoSelezionato={tipoperiodoSelezionato}
              selectPrefferd={selectPrefferd}
            />
          </div>

          <div className="col-lg-12 align-items-center">
            <Accordion activeKey={showMore ? '0' : null}>
              <Accordion.Item eventKey="0">
                <AccordionButton
                  className="custom-accordion-button btn btn-lg btn-blue-cinetel w-100"
                  onClick={() => {
                    setShowMore(!showMore);
                    if (showMore) {
                      resetFiltriAvanzati();
                    }
                  }}>
                  {!showMore ? (
                    <div>
                      <BsFilter
                        className="position-relative me-1"
                        style={{ marginBottom: '3px' }}
                      />
                      <span>Filtri avanzati</span>
                    </div>
                  ) : (
                    <div>
                      <BsBootstrapReboot
                        className="position-relative me-1"
                        style={{ marginBottom: '2px' }}
                      />
                      <span>Annulla Filtri avanzati</span>
                    </div>
                  )}
                </AccordionButton>
                <Accordion.Body>
                  {showMore && (
                    <Fragment>
                      <div className="row align-items-center">
                        <div className="col-12 mb-2">
                          <label>
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              checked={withOutPreview}
                              onChange={(e) => setWithOutPreview((val) => !val)}
                            />
                            Escludi Anteprima
                          </label>
                        </div>
                        <div className="col-8">
                          <label>
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              checked={showMaxPeriodi}
                              onChange={(e) => setShowMaxPeriodi((val) => !val)}
                            />
                            Limita il numero di periodi da analizzare
                          </label>
                        </div>
                        <div className="col">
                          <input
                            type="number"
                            className="form-control mt-1"
                            value={maxPeriodiSelezionato}
                            min={1}
                            style={{
                              visibility: showMaxPeriodi ? 'visible' : 'hidden'
                            }}
                            onChange={(e) => setMaxPeriodiSelezionato(parseInt(e.target.value))}
                          />
                        </div>
                      </div>
                      <div className="row align-items-center">
                        <div className="col-8">
                          <label>
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              checked={showMinData}
                              onChange={(e) => setShowMinData((val) => !val)}
                            />
                            Imposta una data minima
                          </label>
                        </div>
                        <div className="col">
                          <input
                            type="date"
                            className="form-control mt-1"
                            value={minDataSelezionata}
                            style={{
                              visibility: showMinData ? 'visible' : 'hidden'
                            }}
                            onChange={(e) => setMinDataSelezionata(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row align-items-center">
                        <div className="col-8">
                          <label>
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              checked={showMaxData}
                              onChange={(e) => setShowMaxData((val) => !val)}
                            />
                            Imposta una data massima
                          </label>
                        </div>
                        <div className="col">
                          <input
                            type="date"
                            className="form-control mt-1"
                            value={maxDataSelezionata}
                            style={{
                              visibility: showMaxData ? 'visible' : 'hidden'
                            }}
                            onChange={(e) => setMaxDataSelezionata(e.target.value)}
                          />
                        </div>
                      </div>
                    </Fragment>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="col-12 d-flex justify-content-end align-items-center">
            <div className="mx-2 my-2">
              <button
                className="btn btn-lg btn-outline-blue-cinetel mt-3 w-100"
                disabled={invalidAnalisi}
                onClick={startAnalisi}>
                Avvia analisi
                {numFilmSelezionati > MAX_FILMS_IN_DETTAGLIO
                  ? ' (selezionare max ' + MAX_FILMS_IN_DETTAGLIO + ' film)'
                  : ''}
              </button>
            </div>
            {/* <div className="mx-2 my-2">
              <button
                className="btn btn-lg btn-outline-blue-cinetel mt-3 w-100"
                disabled={invalidAnalisi}>
                Salva Analisi
              </button>
            </div> */}
          </div>
        </div>
      </div>

      <div ref={domRisultati}>
        {isContentVisible ? (
          <AnalisiAndamenti
            film={film}
            tipoperiodo={tipoperiodo}
            maxperiodi={maxPeriodi}
            mindata={minData}
            maxdata={maxData}
            preview={withOutPreview}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
