import { Fragment, useCallback, useState } from 'react';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import TableRenderers from 'react-pivottable/TableRenderers';
import { aggregators, numberFormat, aggregatorTemplates } from 'react-pivottable/Utilities';
import 'react-pivottable/pivottable.css';
import DrawerComponent from '../DrawerComponent';
import useFetch from '../../hooks/useFetch';
import { BsSave2 } from 'react-icons/bs';
import fetcher from '../../api/fetcher';
import { Modal, Button } from 'react-bootstrap';

export default function PivotTabella2({ data }) {
  const [pivotTableUIConfig, setPivotTableUIConfig] = useState({
    hiddenFromDragDrop: ['incasso'],
    rows: ['distribuzione'],
    vals: ['incasso']
  });
  const [showModal, setShowModal] = useState(false); // Stato per la modale
  const [pendingSave, setPendingSave] = useState(null); // Stato per tenere traccia della configurazione in attesa di salvataggio

  const { data: data_preferiti, setData: setPreferred } = useFetch(
    '/users/settings/research?type=analisi_film_pivot&preferred=true'
  );
  const { data: data_salvati, setData: setSalvati } = useFetch(
    '/users/settings/research?type=analisi_film_pivot&preferred=false'
  );

  const refreshDataResearch = useCallback(async () => {
    fetcher('/users/settings/research?type=analisi_film_pivot&preferred=true').then(({ data }) => {
      setPreferred(data);
    });
    fetcher('/users/settings/research?type=analisi_film_pivot&preferred=false').then(({ data }) => {
      setSalvati(data);
    });
  }, [setPreferred, setSalvati]);

  const saveToPreferredResearch = (item) => {
    let payload = { ...item, preferred: true };
    fetcher({
      url: `users/settings/research/${item.id}`,
      method: 'PUT',
      data: payload,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(({ data }) => {
      refreshDataResearch();
    });
  };

  const savePivot = useCallback(async () => {
    if (!pendingSave) return;

    let payload = {
      blob: JSON.stringify(pendingSave),
      preferred: false,
      description: new Date(),
      isenabled: true,
      type: 'analisi_film_pivot'
    };

    fetcher({
      url: `users/settings/research`,
      method: 'POST',
      data: payload,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(({ data }) => {
      refreshDataResearch();
      setPendingSave(null); // Resetta lo stato dopo il salvataggio
    });
  }, [pendingSave, refreshDataResearch]);

  const deleteResearch = useCallback(
    async (item) => {
      /* setIsLoading(true); */
      fetcher({
        url: `users/settings/research/${item.id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(({ data }) => {
          refreshDataResearch();
        })
        .finally(() => {
          /* setLoading(false); */
        });
    },
    [refreshDataResearch]
  );


  const handleSaveClick = () => {
    let jsonString = JSON.stringify(pivotTableUIConfig, (key, value) => key === "data" ? undefined : value);
    setPendingSave(JSON.parse(jsonString)); // Imposta la configurazione attuale come pending

    setShowModal(true); // Mostra la modale
  };

  const handleConfirmSave = () => {
    savePivot();
    setShowModal(false); // Nascondi la modale dopo la conferma
  };

  const handleCancelSave = () => {
    setPendingSave(null);
    setShowModal(false); // Nascondi la modale se l'utente annulla
  };

  const selectResearchFromDrawer = (item) => {
    let data = JSON.parse(item.blob);
    setPivotTableUIConfig([data]);
  };

  return (
    <Fragment>
      {}
      {data.length > 0 && (
        <>
          <div className="col-12 d-flex justify-content-center align-items-center mb-5">
            <div className="mx-2 my-2">
              <DrawerComponent
                isPreferred={true}
                data={data_preferiti?.research}
                preferredSave={(e) => saveToPreferredResearch(e)}
                selectResearchFromDrawer={selectResearchFromDrawer}
                 deleteResearch={(e) =>deleteResearch(e)}
              />
            </div>
            <div className="mx-2 my-2">
              <DrawerComponent
                isPivot={true}
                isPreferred={false}
                data={data_salvati?.research}
                preferredSave={(e) => saveToPreferredResearch(e)}
                selectResearchFromDrawer={selectResearchFromDrawer}
                deleteResearch={(e) =>deleteResearch(e)}
              />
            </div>
            <div className="mx-2 my-2">
              <button
                className={'btn btn-blue-cinetel mx-1'}
                title="Salva Tabella Pivot"
                onClick={handleSaveClick} // Usa handleSaveClick al posto di savePivot direttamente
              >
                <BsSave2 className="position-relative me-1" style={{ marginBottom: '3px' }} />
                Salva Tabella Pivot
              </button>
            </div>
          </div>
          <PivotTableUI
            data={data}
            rows={pivotTableUIConfig.length > 0 ? pivotTableUIConfig[0].rows : ['distribuzione']}
            cols={pivotTableUIConfig.length > 0 ? pivotTableUIConfig[0].cols : ['agenzia']}
            vals={pivotTableUIConfig.length > 0 ? pivotTableUIConfig[0].vals : ['incasso']}
            aggregatorName="Integer Sum"
            aggregators={{
              ...aggregators,
              'Integer Sum': aggregatorTemplates.sum(
                numberFormat({ thousandsSep: '.', decimalSep: ',', digitsAfterDecimal: 0 })
              )
            }}
            enableColSort={true}
            rowOrder="value_z_to_a"
            onChange={(s) => setPivotTableUIConfig(s)}
            onRendererUpdate={(s) => setPivotTableUIConfig(s)}
            hiddenFromDragDrop={['incasso']}
            renderers={Object.assign({}, TableRenderers)}
            {...pivotTableUIConfig}
          />
        </>
      )}

      {/* Modale di conferma */}
      <Modal show={showModal} onHide={handleCancelSave}>
        <Modal.Header closeButton>
          <Modal.Title>Conferma Salvataggio</Modal.Title>
        </Modal.Header>
        <Modal.Body>Vuoi salvare questa configurazione della tabella pivot?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelSave}>
            Annulla
          </Button>
          <Button variant="primary" onClick={handleConfirmSave}>
            Salva
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}
