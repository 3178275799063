import React, { useState, useEffect } from 'react';
import PanelFiltri from './PanelFiltri';
import useFetch from '../../hooks/useFetch';
import Form from 'react-bootstrap/Form';
import SelettorePeriodo from './SelettorePeriodo';
import AndamentoPeriodo from './AndamentoPeriodo';
import useScrollTop from '../../hooks/useScrollTop';
import ClassificaFilmNew from '../classifiche/ClassificaFilmNew';
import ClassificaDistribuzioniNew from '../classifiche/ClassificaDistribuzioniNew';
import ClassificaGeneriNew from '../classifiche/ClassificaGeneriNew';
import ClassificaNazioniNew from '../classifiche/ClassificaNazioniNew';
import ClassificaCircuitiNew from '../classifiche/ClassificaCircuitiNew';
import ClassificaCittaNew from '../classifiche/ClassificaCittaNew';
import TotaliPeriodo from './TotaliPeriodo';
import SelettoreDate from './SelettoreDate';

const oggi = new Date();
const ieri = new Date(oggi.setDate(oggi.getDate() - 1));
const ieriStr = ieri.toISOString().slice(0, 10);

export default function DashboardPage() {
  const [filtro, setFiltro] = useState(null);
  const [idperiodo, setIdperiodo] = useState(null);
  const [tipo, setTipo] = useState('incassi');
  const [intervalloDate, setIntervalloDate] = useState([ieriStr, ieriStr, false]);
  const { data } = useFetch(filtro != null ? `periodi/${filtro.id}` : null);
  const [periodi, setPeriodi] = useState(null);

  useScrollTop();

  useEffect(() => {
    if (data) {
      setPeriodi(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (filtro?.id === 0) setIdperiodo(null);
  }, [filtro]);

  useEffect(() => {
    if (periodi && periodi.length > 0) {
      setIdperiodo(periodi[0]['id_periodo']);
    }
  }, [periodi]);

  const setDate = (newDate) => {
    setIntervalloDate(newDate);
  };

  return (
    <div className="container mt-3" style={{ marginBottom: '8rem' }}>
      <PanelFiltri filtro={filtro} setFiltro={setFiltro} />
      {filtro?.id > 0 && (
        <SelettorePeriodo
          periodi={periodi}
          currentIdperiodo={idperiodo}
          setIdperiodo={setIdperiodo}
          filtro={filtro}
        />
      )}
      {filtro?.id === 0 && <SelettoreDate intervallo={intervalloDate} setIntervallo={setDate} />}
      <TotaliPeriodo
        periodo={periodi?.find((x) => x.id_periodo === idperiodo)}
        filtro={filtro}
        intervalloDate={intervalloDate}
      />
      <div className="d-flex justify-content-center mb-3">
        <Form.Check
          type="switch"
          checked={tipo === 'incassi'}
          onChange={() => setTipo((val) => (val === 'incassi' ? 'presenze' : 'incassi'))}
          label={tipo === 'incassi' ? 'Mostra presenze' : 'Mostra incassi'}
        />
      </div>
      <AndamentoPeriodo
        tipo={tipo}
        filtro_selezionato={filtro}
        idperiodo={idperiodo}
        setIdperiodo={setIdperiodo}
        intervalloDate={intervalloDate}
        periodo={periodi?.find((x) => x.id_periodo === idperiodo)}
      />
      <div className="container-fluid mb-5">
        <div className="row mx-auto" style={{ maxWidth: '1000px' }}>
          <div className="col-lg-6">
            <ClassificaFilmNew
              idperiodo={idperiodo}
              filtro={filtro}
              intervalloDate={intervalloDate}
            />
          </div>
          <div className="col-lg-6">
            <ClassificaDistribuzioniNew
              idperiodo={idperiodo}
              filtro={filtro}
              intervalloDate={intervalloDate}
            />
          </div>
          <div className="col-lg-6">
            <ClassificaGeneriNew
              idperiodo={idperiodo}
              filtro={filtro}
              intervalloDate={intervalloDate}
            />
          </div>
          <div className="col-lg-6">
            <ClassificaNazioniNew
              idperiodo={idperiodo}
              filtro={filtro}
              intervalloDate={intervalloDate}
            />
          </div>
          <div className="col-lg-6">
            <ClassificaCittaNew
              idperiodo={idperiodo}
              filtro={filtro}
              intervalloDate={intervalloDate}
            />
          </div>
          <div className="col-lg-6">
            <ClassificaCircuitiNew 
              idperiodo={idperiodo}
              filtro={filtro}
              intervalloDate={intervalloDate}
            />
          </div> 
        </div>
      </div>
    </div>
  );
}
